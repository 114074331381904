import axios from 'axios'
const instance = axios.create({
  baseURL: 'http://115.238.186.109:8311/',
  timeout: 10000
})
// 设置请求拦截器
instance.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        return Promise.reject(error)
    }
)

// 定义响应拦截器 -->token值无效时,清空token,并强制跳转登录页
instance.interceptors.response.use(function (response) {
    if (response.data.status !== 0) {
        this.$message({
            message: "system error",
            type: "warning"
        });
        return Promise.reject(response.data.msg)
    }
    return response
}, function (error) {
    if (error.response.status !== 200) {
        this.$message({
            message: "network error",
            type: "warning"
        });
    }
    return Promise.reject(error)
})

export default instance
